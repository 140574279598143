import React from 'react'
import { Provider } from 'react-redux'
import { store } from '@/store/index'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { warning } from './utils/utility'
import { download } from '@/utils/api'

React.$download = download

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

process.env.NODE_ENV === 'production' &&
(() => { try {
  setInterval(() => {
    warning();
    (
      function () {return false}['constructor']('debugger')['call']()
    )
  }, 50)
} catch (err) {
  //
}
})()
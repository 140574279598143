import { legacy_createStore as createStore, combineReducers } from 'redux'
import { LoadingReducer } from '@/store/reducers/LoadingReducer'
import { ProfileReducer } from '@/store/reducers/ProfileReducer'

const reducers = combineReducers({
  LoadingReducer,
  ProfileReducer
})

const store = createStore(reducers)

export { store }
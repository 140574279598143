import React from 'react'
import { RouterProvider } from 'react-router-dom'
import { connect } from 'react-redux'
import { validLicenseSettings } from './utils/api.js'
import router from './routes/app.js'

import { Enums, Categories } from './utils/settings.js'
import { getEnums, getCategories } from './api/inst.js'
import { Spin } from 'antd'

validLicenseSettings()

function App(props) {
  try {
    getEnums().then((res) => {
      for (let [key, value] of Object.entries(res)) {
        Enums[key] = value
      }
    })
    getCategories({ filter: { type: 'events' }}).then(res => {
      Categories.events = res.list
    })
  } catch (e) {
    //
  }

  return (
    <Spin
      size="large"
      spinning={props.isLoading}
      style={{
        backgroundColor: 'rgba(0,0,0,0.75)',
        minHeight: '100vh',
        position: 'fixed'
      }}
    >
      <React.Suspense fallback={<div>Loading...</div>}>
        <RouterProvider router={router} />
      </React.Suspense>
    </Spin>
  )
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.LoadingReducer.isLoading
  }
}

export default connect(mapStateToProps)(App)